<template>
  <div class="folders">
    <div class="heading">
      <div class="w-auto md:w-full"/>
<!--      <div-->
<!--          class="searchbar"-->
<!--          v-if="showSearchBar">-->
<!--        <form-input :placeholder="$t('generic.search')" v-model="searchInput"></form-input>-->
<!--      </div>-->
    </div>
    <div class="folder-content">
      <folder-container-flickr>
        <folder-element-flickr
            :key="element.id"
            :element="element"
            :showEditButtons="showEditButtons"
            v-for="element in elements"
            @select="selectElement(element)"
            @multiSelect="handleMultiSelect"
            @handleShare="onHandleShare(element)"
            :emitClickEvent="layout === 'splitview'"
            :selected="selectedElements.includes(element.id)">
          {{ element.categoryname }}
        </folder-element-flickr>
      </folder-container-flickr>
      <share-category-modal
          v-if="showShareCategoryModal"
          :category="this.actualElement"
          @showDeleteBox="handleShowDeleteBox"
          @createShare="createShare"
          @close="showShareCategoryModal = false">
      </share-category-modal>

      <confirm-modal
          @success="deleteShare"
          v-if="showDeleteShareModal"
          :title="$t('generic.delete')"
          @close="showDeleteShareModal = false"
          :successButtonTitle="$t('generic.delete')">
        {{ $t('shared.delete_text') }}
      </confirm-modal>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import ContentMixin from '../../mixin/content'
// Import Swiper Vue.js components
// import {SwiperSlide} from 'swiper';
import {Swiper} from 'swiper/vue/swiper-vue.js';
import { SwiperSlide } from 'swiper/vue/swiper-slide.js';
import { Navigation, Pagination, Scrollbar } from 'swiper';
// import { Navigation } from 'swiper/modules/navigation/navigation.js';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar],
    };
  },
// };
//
//
// export default {
  props: [
    'layout',
    'category',
    'isDigitalSignage',
  ],
  computed: {
    ...mapGetters({
      sortType: 'category/sortType',
      sortDirection: 'category/sortDirection',
      selectedElements: 'category/selectedElements',
      showEditButtons: 'category/showEditButtons',
      user: 'auth/user',
    }),
    showSearchBar() {
      return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('EnableSearch')
    },
  },
  data() {
    return {
      elements: [],
      swapped: false,
      showShareCategoryModal: false,
      showDeleteShareModal: false,
      actualElement: '',
      searchInput: '',
      searched: false,
    }
  },
  async created() {
    await this.searchCategories()
    Array.from(document.getElementsByClassName("component-content")).forEach(
        function(element, index, array) {
          element.style.maxWidth = 'none'
        }
    );
  },
  methods: {
    onHandleShare($event) {
      this.actualElement=$event
      this.showShareCategoryModal = true
    },
    handleShowDeleteBox() {
      this.showDeleteShareModal = true;
    },
    async deleteShare() {
      await this.$api.deleteShare(this.actualElement.id)
      this.$toast.success(this.$t('shared.deleted'), { position: 'top-right'})
      this.showDeleteShareModal = false;
      this.showShareCategoryModal = false;
      await this.loadCategories()
    },
    async createShare() {
      let response = await this.$api.createShare(this.actualElement.id)
      if (response) {
        this.$toast.success(this.$t('shared.created'), { position: 'top-right'})
        this.showShareCategoryModal = false;
        setTimeout(this.waitForShare, 1000);
      }
    },
    async waitForShare() {
      await this.loadCategories()
      for (let i = 0; i < this.elements.length; i++) {
        if (this.elements[i].id === this.actualElement.id) {
          this.actualElement = this.elements[i];
          break;
        }
      }
      this.showShareCategoryModal = true;
    },
    async selectElement(category) {
      const componentType = this.$helper.resolveComponentTypeByCategory(category)
      if (this.layout === 'splitview' && componentType !== 'folder') {
        return this.$emit('selectElement', category)
      }
      this.elements = await this.$api.findCategories(this.$helper.extractIdFromSlug(category.id))
      this.$store.commit('category/setElements', this.elements)
    },
    async handleMultiSelect($event) {
      let currentSelectedElements = JSON.parse(JSON.stringify(this.selectedElements))
      if (currentSelectedElements.includes($event)) {
        currentSelectedElements = currentSelectedElements.filter(element => element !== $event)
      } else {
        currentSelectedElements.push($event)
      }
      this.$store.commit('category/setSelectedElements', currentSelectedElements)
    },
    async searchCategories() {
      // let z = this.category?.breadcrumbs[0].id
      // if (this.user.startId) z = this.user.startId
      this.elements = await this.$api.searchCategories(this.$helper.extractIdFromSlug(this.category.quicklink), this.sortType, this.sortDirection, null)
      this.$store.commit('category/setElements', this.elements)
    },
    async doSearchCategories() {
      if (this.searchInput === '') {
        await this.loadCategories()
        this.searched = false;
        return
      }
      let sb = this.category?.breadcrumbs[1].id
      let z = '*' + this.searchInput + '*'
      this.elements = await this.$api.searchCategories(z, this.sortType, this.sortDirection, sb)
      this.$store.commit('category/setElements', this.elements)
    }
  },
  watch: {
    searchInput() {
      this.searched = true;
      this.doSearchCategories()
    },
  },
  mixins: [
    ContentMixin,
  ],
  inject: [
    '$api',
    '$helper',
  ],
  emits: [
    'selectElement'
  ]
}
</script>

<style lang="scss" scoped>
.folders {
  @apply
  md:pl-0
  md:pt-0
  md:flex
  md:w-full
  md:h-full
  md:flex-col;

  .heading {
    @apply
    flex
    p-2
    gap-2
    md:py-2
    md:px-7
    w-full
    items-center
    justify-between;

    .title {
      @apply
      text-2xl;
    }

    .searchbar {
      @apply
      w-full
      md:w-1/3;
    }
  }

  .folder-content {
    @apply
    flex
    w-full
    h-full
    items-center
    overflow-y-auto;
  }
}
</style>